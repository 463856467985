import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { BASE_URL } from "../../../shared/constants";

const NewJobForm = () => {
  const navigate = useNavigate();

  // Initial empty state
  const [formData, setFormData] = useState({
    jobOptions: [{ label: "", value: "" }],
    categoryOptions: [{ label: "", value: "" }],
    locationOptions: [{ label: "", value: "" }],
    tagOptions: [{ label: "", value: "" }],
    jobTypeOptions: [{ label: "", value: "" }],
  });

  // Handler to update the input fields
  const handleChange = (e, category, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));
  };

  // Handler to add a new item to the category array
  const handleAddItem = (category) => {
    setFormData((prevData) => ({
      ...prevData,
      [category]: [
        ...prevData[category],
        { label: "", value: "" }, // New item with empty fields
      ],
    }));
  };

  // Handler to delete an item from the category array
  const handleDeleteItem = (category, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [category]: prevData[category].filter((_, i) => i !== index),
    }));
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("post data", formData);
    try {
      await axios.post(`${BASE_URL}/jobs-form-data`, formData);
      alert("New form created successfully!");
      navigate("/dashboard/form");
    } catch (error) {
      alert("Error creating form: " + error.message);
    }
  };

  return (
    <div>
      <h1>Create New Job Form</h1>
      <form onSubmit={handleSubmit}>
        <h2>Job Options</h2>
        {formData.jobOptions.map((job, index) => (
          <div key={index}>
            <label>Label:</label>
            <input
              name="label"
              value={job.label}
              onChange={(e) => handleChange(e, "jobOptions", index)}
            />
            <label>Value:</label>
            <input
              name="value"
              value={job.value}
              onChange={(e) => handleChange(e, "jobOptions", index)}
            />
            <button
              type="button"
              onClick={() => handleDeleteItem("jobOptions", index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddItem("jobOptions")}>
          Add Job Option
        </button>

        <h2>Category Options</h2>
        {formData.categoryOptions.map((category, index) => (
          <div key={index}>
            <label>Label:</label>
            <input
              name="label"
              value={category.label}
              onChange={(e) => handleChange(e, "categoryOptions", index)}
            />
            <label>Value:</label>
            <input
              name="value"
              value={category.value}
              onChange={(e) => handleChange(e, "categoryOptions", index)}
            />
            <button
              type="button"
              onClick={() => handleDeleteItem("categoryOptions", index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddItem("categoryOptions")}>
          Add Category Option
        </button>

        <h2>Location Options</h2>
        {formData.locationOptions.map((location, index) => (
          <div key={index}>
            <label>Label:</label>
            <input
              name="label"
              value={location.label}
              onChange={(e) => handleChange(e, "locationOptions", index)}
            />
            <label>Value:</label>
            <input
              name="value"
              value={location.value}
              onChange={(e) => handleChange(e, "locationOptions", index)}
            />
            <button
              type="button"
              onClick={() => handleDeleteItem("locationOptions", index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddItem("locationOptions")}>
          Add Location Option
        </button>

        <h2>Tag Options</h2>
        {formData.tagOptions.map((tag, index) => (
          <div key={index}>
            <label>Label:</label>
            <input
              name="label"
              value={tag.label}
              onChange={(e) => handleChange(e, "tagOptions", index)}
            />
            <label>Value:</label>
            <input
              name="value"
              value={tag.value}
              onChange={(e) => handleChange(e, "tagOptions", index)}
            />
            <button
              type="button"
              onClick={() => handleDeleteItem("tagOptions", index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddItem("tagOptions")}>
          Add Tag Option
        </button>

        <h2>Job Type Options</h2>
        {formData.jobTypeOptions.map((jobType, index) => (
          <div key={index}>
            <label>Label:</label>
            <input
              name="label"
              value={jobType.label}
              onChange={(e) => handleChange(e, "jobTypeOptions", index)}
            />
            <label>Value:</label>
            <input
              name="value"
              value={jobType.value}
              onChange={(e) => handleChange(e, "jobTypeOptions", index)}
            />
            <button
              type="button"
              onClick={() => handleDeleteItem("jobTypeOptions", index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddItem("jobTypeOptions")}>
          Add Job Type Option
        </button>

        <button type="submit">Create Form</button>
      </form>
    </div>
  );
};

export default NewJobForm;
