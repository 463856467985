import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { dummy } from "./Index";
import axios from "axios";
import { BASE_URL } from "../../../shared/constants";
import Loader from "../../../shared/Loader";

const CandidateListing = () => {
  const [createManager, setCreateManager] = useState(false);
  const [Edit_detail, setEdit] = useState({});
  const [action, setAction] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const createRole = () => {
    setCreateManager(true);
  };

  useEffect(() => {
    getCandidateList();
  }, []);
  //const [data, setData] = useState(dummy);
  const getCandidateList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/candidate`);
      setData(res?.data?.data);
    } catch (e) {
      alert("error in get candidate listing");
    } finally {
      setLoading(false);
    }
  };
  const deleteCandidate = async (id) => {
    try {
      const res = await axios.delete(`${BASE_URL}/candidate/${id}`);
      getCandidateList();
    } catch (e) {
      alert("error in delete job");
    }
  };

  return (
    <div className="blogs-container">
      <>
        <div className="blog-container">
          <div className="blog-header-container">
            <span className="blog-header">Candidate Listing</span>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <UserTable
            data={data}
            deleteCandidate={deleteCandidate}
            setEdit={setEdit}
            setAction={setAction}
            setCreateManager={setCreateManager}
          />
        )}
      </>
    </div>
  );
};

export default CandidateListing;
