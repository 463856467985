const routes = [
  {
    name: "Blog",

    children: [
      {
        name: "Add Banner",
        path: "/dashboard/banner",
      },
      {
        name: "Blog Listing",
        path: "/dashboard/blog",
      },
    ],
    access:["ADMIN"]
  },
  {
    name: "Press",
    path: "/dashboard/newslink",
    access:["ADMIN"]
  },
  {
    name: "Contact",
    path: "/dashboard/contact-us",
    access:["ADMIN"]
  },
  {
    name: "Jobs",
    path: "/dashboard/jobs",
    access:["ADMIN"]
  },
  {
    name: "Store Locations",
    path: "/dashboard/store-location",
    access:["ADMIN"]
  },
  {
    name: "FAQs",
    path: "/dashboard/faq/category-list",
    access:["ADMIN"]
  },
  {
    name: "Campaign Review",
    path: "/dashboard/testimonial-list",
    access:["ADMIN"]
  },
  {
    name: "Interim Storage",
    path: "/dashboard/interim-storage",
    access:["ADMIN"]
  },

  // {
  //   name: "HR Login",
  //   path: "/hr-portal",
  //   access:["ADMIN","HR"]
  // },

  {
    name: "Candidate Listing",
    path: "/hr-portal/candidate-listing",
    access:["HR"]
  },

  {
    name: "Job Listing",
    path: "/hr-portal/job-listing",
    access:["HR"]
  },
  
  {
    name: "Dussehra Campaign",
    path: "/dashboard/event-lead",
    access:["ADMIN"]
  },
  
  {
    name: "Dealership Leads",
    path: "/dashboard/dealership-lead",
    access:["ADMIN"]
  },
];

export default routes;
