import React from "react";

import { Route, Routes } from "react-router-dom";

import Login from "./features/auth";
import SidebarLayout from "./features/side-bar/SidebarLayout";
import PrivateRoute from "./features/routes/PrivateRoute";
import PublicRoute from "./features/routes/PublicRoute";
import Blog from "./features/blog/Blog";
import NewsLink from "./features/newsLink/NewsLink";
import Blogdetail from "./features/blog/Blogdetail";
import CreateForm from "./features/blog/CreateForm";
// import DeleteModal from "./features/Common/DeleteModal";
import Linkdetail from "./features/newsLink/Linkdetail";
import Createlink from "./features/newsLink/Createlink";
import "./App.css";
import Deletelink from "./features/newsLink/Deletelink";
import Contactpage from "./features/ContactUs/Contactpage";
import GetJobs from "./features/Jobs/GetJobs";
import StoreLocattion from "./features/store-locations/StoreLocattion";
import EditStorePage from "./features/store-locations/components/EditStorePage";
import BannerBlogAccordion from "./features/blog/accordians/BannerListing";
import CreateBanner from "./features/blog/accordians/CreateBanner";
import BannerListing from "./features/blog/accordians/BannerListing";
import BannerDetail from "./features/blog/accordians/BannerDetail";
import CategoryList from "./features/FaqCategory/components/CategoryList";
import AddCategory from "./features/FaqCategory/components/AddCategory";
import EditCategory from "./features/FaqCategory/components/EditCategory";
import TestimonailList from "./features/Testimonial/components/TestimonailList";
import PerformanceReport from "./features/performance-report/PerformanceReport";
import LoginHR from "./features/hr-container/login/LoginHR";
import CandidateListing from "./features/hr-container/candidate/CandidateListing";
import JobListing from "./features/hr-container/job/JobListing";
import CandidateDetail from "./features/hr-container/candidate/CandidateDetail";
import JobDetail from "./features/hr-container/job/JobDetail";
import JobsFormData from "./features/hr-container/jobFormData/jobFormDataList";
import EditJobForm from "./features/hr-container/jobFormData/EditJobForm";
import NewJobForm from "./features/hr-container/jobFormData/NewJobForm";

import EventLead from "./features/event-lead/EventLead";
import DealershipLead from "./features/dealership-lead/DealershipLead";

function App() {
  return (
    <Routes>
      <Route element={<SidebarLayout />}>
        <Route
          path="/dashboard/blog"
          element={
            <PrivateRoute slug="Blog">
              <Blog />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/banner"
          element={
            <PrivateRoute slug="Blog">
              <BannerListing />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/newslink"
          element={
            <PrivateRoute slug="Press">
              <NewsLink />
            </PrivateRoute>
          }
        />
        <Route
          bannerdetail
          path="/dashboard/blogdetail"
          element={
            <PrivateRoute slug="Blog">
              <Blogdetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/bannerdetail"
          element={
            <PrivateRoute slug="Blog">
              <BannerDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/linkdetail"
          element={
            <PrivateRoute slug="Press">
              <Linkdetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/create/blog"
          element={
            <PrivateRoute slug="Blog">
              <CreateForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/create/banner"
          element={
            <PrivateRoute slug="Blog">
              <CreateBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/create/link"
          element={
            <PrivateRoute slug="Press">
              <Createlink />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/contact-us"
          element={
            <PrivateRoute slug="Contact">
              <Contactpage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/jobs"
          element={
            <PrivateRoute slug="Jobs">
              <GetJobs />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/store-location"
          element={
            <PrivateRoute slug="Store Locations">
              <StoreLocattion />
            </PrivateRoute>
          }
        />
        {["/dashboard/editStore", "/dashboard/editStore/:id"].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute slug="Store Locations">
                <EditStorePage />
              </PrivateRoute>
            }
          />
        ))}
        <Route
          path="/dashboard/delete/link"
          element={
            <PrivateRoute slug="Press">
              <Deletelink />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/faq/category-list"
          element={
            <PrivateRoute slug="FAQs">
              <CategoryList />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/faq/category-add"
          element={
            <PrivateRoute slug="FAQs">
              <AddCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/faq/category-edit/:id"
          element={
            <PrivateRoute slug="FAQs">
              <EditCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/testimonial-list"
          element={
            <PrivateRoute slug="Campaign Review">
              <TestimonailList />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/form"
          element={
            // <PrivateRoute slug="Form Review">
            <JobsFormData />
            // </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/form/edit"
          element={
            // <PrivateRoute slug="Form Review">
            <EditJobForm />
            // <JobsFormData />
          }
        />
        <Route
          path="/dashboard/form/create"
          element={
            // <PrivateRoute slug="Form Review">
            <NewJobForm />
            // <JobsFormData />
          }
        />
        <Route
          path="/dashboard/interim-storage"
          element={
            <PrivateRoute slug="Interim Storage">
              <PerformanceReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/event-lead"
          element={
            <PrivateRoute slug="Dussehra Campaign" > 
              <EventLead />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/dealership-lead"
          element={
            <PrivateRoute slug="Dealership Leads" >
              <DealershipLead />
            </PrivateRoute>
          }
        />
        {/* hr login code */}
        <Route
          path="/hr-portal/candidate-listing"
          element={
            <PrivateRoute slug="Candidate Listing">
              <CandidateListing />
            </PrivateRoute>
          }
        />
        <Route
          path="/hr-portal/candidate-detail/:id"
          element={
            <PrivateRoute slug="Candidate Listing">
              <CandidateDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/hr-portal/job-listing"
          element={
            <PrivateRoute slug="Job Listing">
              <JobListing />
            </PrivateRoute>
          }
        />

        <Route
          path="/hr-portal/job-detail/:id"
          element={
            <PrivateRoute slug="Job Listing">
              <JobDetail />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/hr-portal" element={<LoginHR />} />

      {/* hr login code */}

      <Route
        exact
        path="/"
        element={
          // <PublicRoute>
          <Login />
          // </PublicRoute>
        }
      />
    </Routes>
  );
}

export default App;
